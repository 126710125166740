import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useInterval } from 'react-use';
import styles from './TimerPreview.module.css';
import PropTypes from "prop-types";
import {get} from "../../ui/lodash";

export function TimerPreview({shop}) {
  const { uiConfig: { saleTimers } = {} } = shop;
  const config = get(shop, 'helloBar', {});
  const timerId = get(config, 'timerId', '');
  const getTimer = () => {
    if (!timerId) {
      return null;
    }
    return saleTimers.find(t => t._id === timerId);
  }
  if(getTimer() === null) {
    return null;
  }
  const bgColor = getTimer().backgroundColor;
  const labelColor = getTimer().labelColor;
  const digitBackgroundColor = getTimer().digitBackgroundColor;
  const digitColor = getTimer().digitColor;
  const type = getTimer().timerStyle;
  const startDate = getTimer().startDate;
  const endDate = getTimer().endDate;
  const today = dayjs();
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const displayTimer = today.isAfter(start) && today.isBefore(end);
  const timerFields = getTimer().timerFormat;
  const showDays = timerFields?.days;
  const showHours = timerFields?.hours;
  const showMinutes = timerFields?.minutes;
  const showSeconds = timerFields?.seconds;
  const [remainingTime, setRemainingTime] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });
  const formatTime = (time) => time.toString().padStart(2, '0');
  useInterval(() => {
    const intervalId = setInterval(() => {
      const now = dayjs().toString();
      const end = dayjs(endDate).toString();
      const diff = dayjs(end).diff(now)
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const remainingHours = hours % 24;
      const remainingMinutes = minutes % 60;
      const remainingSeconds = seconds % 60;
      setRemainingTime({
        days: formatTime(days),
        hours: formatTime(remainingHours),
        minutes: formatTime(remainingMinutes),
        seconds: formatTime(remainingSeconds)
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  },);
  return (
    <>
      {displayTimer  && (
        <>
          {type === 'timerOne' && (
            <div className={styles.mainContainer} style={{ backgroundColor: bgColor }}>
              {showDays && (
                <div className={styles.timerContainer} style={{ color: labelColor, backgroundColor: bgColor }}>
                  <div className={styles.timer} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                    {remainingTime.days}
                  </div>
            Days
                </div>
              )}
              {showHours && (
                <div className={styles.timerContainer} style={{ color: labelColor, backgroundColor: bgColor }}>
                  <div className={styles.timer} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                    {remainingTime.hours}
                  </div>
            Hours
                </div>
              )}
              {showMinutes && (
                <div className={styles.timerContainer} style={{ color: labelColor, backgroundColor: bgColor }}>
                  <div className={styles.timer} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                    {remainingTime.minutes}
                  </div>
            Mins
                </div>)}
              {showSeconds && (
                <div className={styles.timerContainer} style={{ color: labelColor, backgroundColor: bgColor }}>
                  <div className={styles.timer} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                    {remainingTime.seconds}
                  </div>
            Sec
                </div>)}
            </div>
          )}
          {type === 'timerTwo' && (
            <div className={styles.mainContainer2} style={{ backgroundColor: bgColor }}>
              {showDays && (
                <div className={styles.timerContainer2} style={{ color: labelColor, backgroundColor: bgColor }}>
                  <div className={styles.timerTwo}>
                    <div className={styles.timer2} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                      {remainingTime.days.toString().padStart(2, '0').charAt(0)}
                    </div>
                    <div className={styles.timer2} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                      {remainingTime.days.toString().padStart(2, '0').charAt(1)}
                    </div>
                  </div>
            DAYS
                </div>)}
              {showHours && (
                <div className={styles.timerContainer2} style={{ color: labelColor, backgroundColor: bgColor }}>
                  <div className={styles.timerTwo}>
                    <div className={styles.timer2} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                      {remainingTime.hours.toString().padStart(2, '0').charAt(0)}
                    </div>
                    <div className={styles.timer2} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                      {remainingTime.hours.toString().padStart(2, '0').charAt(1)}
                    </div>
                  </div>
            HOURS
                </div>)}
              {showMinutes && (
                <div className={styles.timerContainer2} style={{ color: labelColor, backgroundColor: bgColor }}>
                  <div className={styles.timerTwo}>
                    <div className={styles.timer2} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                      {remainingTime.minutes.toString().padStart(2, '0').charAt(0)}
                    </div>
                    <div className={styles.timer2} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                      {remainingTime.minutes.toString().padStart(2, '0').charAt(1)}
                    </div>
                  </div>
            MINS
                </div>)}
              {showSeconds && (
                <div className={styles.timerContainer2} style={{ color: labelColor, backgroundColor: bgColor }}>
                  <div className={styles.timerTwo}>
                    <div className={styles.timer2} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                      {remainingTime.seconds.toString().padStart(2, '0').charAt(0)}
                    </div>
                    <div className={styles.timer2} style={{ backgroundColor: digitBackgroundColor, color: digitColor }}>
                      {remainingTime.seconds.toString().padStart(2, '0').charAt(1)}
                    </div>
                  </div>
            SEC
                </div>
              )}
            </div>
          )}
          {type === 'timerThree' && (
            <div className={styles.mainContainer3} style={{ backgroundColor: bgColor }}>
              {showDays && (
                <div className={styles.timerThree}>
                  <div className={styles.timer3} style={{ backgroundColor: bgColor, color: labelColor }}>
              Days
                  </div>
                  <div
                    className={styles.timerContainer3}
                    style={{
                      color: digitColor,
                      backgroundColor: digitBackgroundColor
                    }}>
                    {remainingTime.days}
                  </div>
                </div>)}
              {showHours && (
                <div className={styles.timerThree}>
                  <div className={styles.timer3} style={{ backgroundColor: bgColor, color: labelColor }}>
              Hours
                  </div>
                  <div
                    className={styles.timerContainer3}
                    style={{
                      color: digitColor,
                      backgroundColor: digitBackgroundColor
                    }}>
                    {remainingTime.hours}
                  </div>
                </div>)}
              {showMinutes && (
                <div className={styles.timerThree}>
                  <div className={styles.timer3} style={{ backgroundColor: bgColor, color: labelColor }}>
              Mins
                  </div>
                  <div
                    className={styles.timerContainer3}
                    style={{
                      color: digitColor,
                      backgroundColor: digitBackgroundColor
                    }}>
                    {remainingTime.minutes}
                  </div>
                </div>)}
              {showSeconds && (
                <div className={styles.timerThree}>
                  <div className={styles.timer3} style={{ backgroundColor: bgColor, color: labelColor }}>
              Sec
                  </div>
                  <div
                    className={styles.timerContainer3}
                    style={{
                      color: digitColor,
                      backgroundColor: digitBackgroundColor
                    }}>
                    {remainingTime.seconds}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

TimerPreview.propTypes = {
  shop: PropTypes.object.isRequired,
};

TimerPreview.defaultProps = {};

export default TimerPreview;
