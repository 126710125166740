import { useIsCustomDomain } from 'contexts/profile';
import { useRouter } from 'next/router';

export function usePageName() {
  const isCustomDomain = useIsCustomDomain();
  const { route } = useRouter();
  switch (route) {
    case '/':
      return isCustomDomain ? 'home' : 'index';
    case '/[slug]':
      return isCustomDomain ? 'pdp' : 'home';
    case '/[slug]/[id]':
      return 'pdp';
    case '/[slug]/cart':
      return 'cart';
    case '/cart':
      return isCustomDomain ? 'cart' : 'home';
    case '/[slug]/featured':
      return 'featured';
    case '/featured':
      return isCustomDomain ? 'featured' : 'home';
    case '/[slug]/search':
      return 'search';
    case '/search':
      return isCustomDomain ? 'search' : 'home';
    case '/[slug]/catalogList':
      return 'catalogList';
    case '/catalogList':
      return isCustomDomain ? 'catalogList' : 'home';
    case '/[slug]/catalogs':
      return 'productList';
    case '/catalogs':
      return isCustomDomain ? 'productList' : 'home';
    default:
      return '';
  }
}
