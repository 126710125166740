import React from 'react';
import PropTypes from 'prop-types';
import { helloBarIcon } from 'components/shared/Icons/Shop';
import _, { get } from 'ui/lodash';
import { useQueryParams } from 'hooks/location';
import styles from './HelloBar.module.css';
import { Clickable } from 'phoenix-components';
import { usePageName } from 'hooks/page';
import { TimerPreview } from './TimerPreview';
import { Container } from 'components/layout/Container';

const PATTERNS = {
  pattern1: '/images/helloBar/pattern1.svg',
  pattern2: '/images/helloBar/pattern2.svg',
  pattern3: '/images/helloBar/pattern3.svg',
};

const getStyles = helloBar => {
  const styles = {
    textAlign: _.isEmpty(helloBar?.button?.text) ? 'center' : 'justify',
  };
  const { background = {} } = helloBar || {};
  if (_.isEmpty(background?.value)) {
    styles.backgroundColor = 'var(--primary20)';
    return styles;
  }

  if (!_.isEmpty(helloBar?.textColor)) {
    styles.color = helloBar.textColor;
  }

  if (background?.type === 'color') {
    styles.backgroundColor = background.value;
  }
  if (background?.type === 'image') {
    styles.backgroundImage = `url(${background.value})`;
    styles.backgroundRepeat = 'no-repeat';
  }
  if (background?.type === 'pattern') {
    if (PATTERNS[background.value]) {
      styles.backgroundImage = `url(${PATTERNS[background.value]})`;
      styles.backgroundRepeat = 'repeat';
    }
  }
  return styles;
};

export function HelloBar({ shop }) {

  const params = useQueryParams();
  const pageName = usePageName();

  const helloBarPreviewText = params.has('helloBarText') ? params.get('helloBarText') : '';

  if (helloBarPreviewText === 'empty') {
    return null;
  }

  const helloBar = get(shop, 'helloBar.title', helloBarPreviewText);

  if (_.isEmpty(helloBar?.trim())) {

    return null;

  }

  const config = get(shop, 'helloBar', {});

  const { visibility = {} } = config;

  if (!visibility[pageName]) {
    return null;
  }

  const bgStyles = getStyles(config);

  const onClick = () => {
    if (config.button?.url) {
      window.open(config.button.url, '_blank', 'noopener nofollow no-referrer');
    }
  };

  return (
    <div
      className={styles.helloBar}
      style={{
        ...bgStyles
      }}
    >
      <Container className={styles.text}>
        <div className={styles.textBar}>
          {_.isEmpty(config?.background?.value) && (<span>
            {helloBarIcon()}
          </span>)}
          {helloBar}
        </div>
        {!_.isEmpty(config?.button?.text) &&
          <div
            style={{
              '--hellobarBtnBgColor':config.button.backgroundColor,
              '--hellobarBtnColor':config.button.color
            }}
            className={styles.buttonContainer}
          >
            <Clickable
              className={styles.button}
              onClick={onClick}
            >
              {config.button.text}
            </Clickable>
          </div>
        }
        {<div className={styles.timer}><TimerPreview shop={shop} /></div>}
      </Container>
      {_.isEmpty(config?.background?.value) && (<>
        <img className={styles.one} src="/images/store/hellobar/1.svg" alt=""/>
        <img className={styles.two} src="/images/store/hellobar/2.svg" alt=""/>
        <img className={styles.three} src="/images/store/hellobar/3.svg" alt=""/>
        <img className={styles.four} src="/images/store/hellobar/4.svg" alt=""/>
        <img className={styles.five} src="/images/store/hellobar/5.svg" alt=""/>
        <img className={styles.s_x} src="/images/store/hellobar/6.svg" alt=""/>
      </>)}
    </div>
  );

}

HelloBar.propTypes = {
  shop: PropTypes.object.isRequired,
};

HelloBar.defaultProps = {};
